import { Button, Grid, Typography } from '@mui/material';
import React from 'react'
import "./components_bharath.scss";
import Topimg from '../../../assets/tower.png';
import BottomImg from '../../../assets/hand.png';

const featuredList=[
        {feature:'Budget',value:'Confidential'},
        {feature:'Size',value:'1200 m2'},
        {feature:'Type',value:'Office'},
        {feature:'Status',value:'Done'},
        {feature:'Location',value:'Switzerland'},
        {feature:'Flat',value:'8 Room'}
]; 

export default function FeaturedList() {
  return (
<div className='featuredListContainer'>
{/* title featured listing of the week */}
    {/* <Typography variant='h1' className='featuredListingTitle'>
     About Us
    </Typography> */}
    
{/* container for left n right containers */}
    <Grid container className='featuredListBox' direction={{md:'row',xs:'column'}}
    justifyContent={{md:'flex-start',xs:'flex-start'}} alignItems={{md:'flex-start',xs:'center'}}>

{/* left container */}
      <Grid item className='leftContainer' md={5} >
              <img className='topImg' src={Topimg} alt='img'/>
              {/* <img className='bottomImg' src={BottomImg} alt='img'/> */}
      </Grid>

{/* right container */}
      <Grid item className='rightContainer' container direction={{md:'column',xs:'column'}} 
      rowSpacing={{md:2,xs:4}} md={5}  
      justifyContent={{md:'center',xs:'center'}} alignItems={{md:'flex-start',xs:'center'}}>
        
          <Grid item >
            <Typography variant='h4' className='rightContainerTitle'>
            Our Vison & Mission
            </Typography>
          </Grid>
        
          <Grid item >
            <Typography variant='body1' className='rightContainerDescr'>
            <b>Our Vision</b> is to focus on customer service, transparency and integrity.            </Typography>
          </Grid>

          <Grid item >
            <Typography variant='body1' className='rightContainerDescr'>
            <b>Our Mission </b>is to be committed to understanding and fulfilling the unique needs of each client, ensuring smooth transactions.  </Typography>
          </Grid>

          <Grid item >
            <Typography variant='body1' className='rightContainerDescr'>
            <b>Our Goal </b> is to year on year increase our productivity by 25% by using digital and smart marketing ideas.  </Typography>
          </Grid>

          <Grid item >
            <Typography variant='body1' className='rightContainerDescr'>
            <b>And this can be only achieved by team work</b> </Typography>
          </Grid>

          <Grid item >
            <Typography variant='body1' className='rightContainerDescr'>
            <b>My top 5 strengths are:  </b> Activator | Positivity | Responsibility | Communication | Futuristic   </Typography>
          </Grid>
       
          {/* <Grid item container rowSpacing={3.3} columnSpacing={2.2} >
              {featuredList.map(obj=>(
                <Grid item md={4} xs={6}>
                <Typography className='rightContainerFeatures' variant='h6'>{obj.feature}</Typography>
                <Typography className='rightContainerFeatureValues' variant='p'>{obj.value}</Typography>
              </Grid>
              ))}
          </Grid> */}

          {/* <Grid item >
            <button id='rightContainerButton' >
              YES! I WANT BOOK “OFFICE PACKAGE”
            </button>
          </Grid> */}
      </Grid>
    </Grid>
  </div>
  )
}
