import React, { useState } from "react";
import "./cards.scss";

function HowItWorksCard(props) {
  return (
    <div className="howitworks-box">
      <div className="howitworks-fill" style={{ backgroundColor: props.color }}>
        <img width="110" height="110" alt="icon" src={props.icon} className="howitworks-icon" />
      </div>
      <div className="howitworks-title">{props.title}</div>
      {/* <div className="howitworks-info">{props.info}</div> */}
    </div>
  );
}
export default HowItWorksCard;
