import React, { useState } from "react";
import { Grid} from "@mui/material";
import "./components.scss";
import MemberCard from "./cards/MemberCard";
import Icon1 from "../../../assets/brai.jpg";
import Icon2 from "../../../assets/nar.png";
import Icon3 from "../../../assets/krera.jpg";
import Icon4 from "../../../assets/crea.jpg";
import Icon5 from "../../../assets/usa.png";

function Member(props) {
const windowWidth = window.innerWidth;

  return (
    <div className="">
      <Grid container>
        <Grid container className="howitworksText" alignItems="center" justifyContent="center">
            <div style={{height: "55px",fontFamily: "Manrope",fontStyle: "normal",
            fontWeight: "600",fontSize: windowWidth < 600 ? "1.5rem" : "1.5rem",lineHeight: "55px",color: "#000000",textAlign: "center"}}>
               Memberships & Associations
            </div>
        </Grid>
        <div  className="howitworksCards" spacing={windowWidth< 600 ? 4 : 12} style={{width: windowWidth-10, margin: "0.5rem", paddingTop: 0, height: windowWidth< 600 ? "2rem" : "6rem", display: "flex", justifyContent: "space-evenly", flexDirection: "row"}}>
            <MemberCard
              color="#4DDFFD"
              icon={Icon2}
              title="Property analytics & recommendation"
              info="Conveying or northward offending admitting perfectly my. Colonel gravit and moonlight."
            />
            <MemberCard
              color="#9672FF"
              icon={Icon1}
              title="Understanding Client property needs"
              info="Wonder twenty hunted and put income set desire expect. Am cottage calling."
            />
            <MemberCard
              color="#9672FF"
              icon={Icon3}
              title="Site visits & final selection"
              info="Moderate children at of outweigh it. Unsatiable it considered invitation he travelling insensible."
            />
            <MemberCard
              color="#9672FF"
              icon={Icon4}
              title="Site visits & final selection"
              info="Moderate children at of outweigh it. Unsatiable it considered invitation he travelling insensible."
            />
            <MemberCard
              color="#4DDFFD"
              icon={Icon5}
              title="Property analytics & recommendation"
              info="Conveying or northward offending admitting perfectly my. Colonel gravit and moonlight."
            />
        </div>
      </Grid>
    </div>
  );
}
export default Member;
