import React, { useState } from "react";
import "./cards.scss";

function MemberCard(props) {
  const windowWidth = window.innerWidth;
  return (
    <div className="howitworks-box" style={{height: "10rem", display: "flex", justifyContent: "center"}}>
      {/* <div className="howitworks-fill" style={{ backgroundColor: props.color }}> */}
        <img height={windowWidth < 600 ? "40" : "80"} alt="icon" src={props.icon} style={{borderRadius: 20}} />
      {/* </div> */}
      {/* <div className="howitworks-title">{props.title}</div> */}
      {/* <div className="howitworks-info">{props.info}</div> */}
    </div>
  );
}
export default MemberCard;
