import {
  Box,
  Button,
  Grid,
  Link,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import "./components_bharath.scss";
import HomeBg from "../../../assets/Cundall-Bengaluru.png";
import { Opacity } from "@mui/icons-material";
// import HomeBg from "../../../assets/Alt_main.png"
// import HomeBg from "../../../assets/AltMain2.jpg"
import Paper from "@mui/material/Paper";
import RentIcon from "../../../assets/for-rent.svg";
import BuyIcon from "../../../assets/buy.svg";
import StampIcon from "../../../assets/stamp.svg";
import "./pricing.scss";

export default function Pricing() {
  const windowWidth = window.innerWidth;

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <div
      style={{ height: "100vh", marginTop: "5rem", overflowX: "hidden" }}
      className="feeHome"
    >
      <img
        src={HomeBg}
        style={{ width: windowWidth, height: "90vh" }}
        className="homeBg"
        alt="backgroundimage"
      />
      <Grid container className="navHomeContainer">
        {/* home section body */}
        <Box className="navHomeBodyContainer">
          <Grid
            container
            className="howitworksText"
            alignItems="center"
            justifyContent="right"
          >
            <div
              style={{
                width: "262px",
                height: "55px",
                fontFamily: "Manrope",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "40px",
                lineHeight: "55px",
                color: "#000000",
                textAlign: "center",
                marginBottom: "2rem"
              }}
            >
              Service Fee
            </div>
          </Grid>
          <Grid
            container
            className="feeDetials"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            // style={{ overflowX: "clip" }}
          >
            <Grid item xs={12} sm={6}>
              <div
                className="feeProperty"
              >
                Residential/ <br /> Commercial Property
              </div>
            </Grid>
            <Grid item xs={11} sm={6} style={{paddingLeft: "0px"}}>
              <Item
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                }}
              >
                <img alt="icon" src={RentIcon} className="feeIcon" />
                <span style={{ textAlign: "left", paddingRight: "1rem" }}>
                  <h2>Rentals</h2>
                  <p>1 month rent + 18% GST</p>
                </span>
              </Item>
            </Grid>
            <Grid item xs={11} style={{paddingLeft: "0px"}}>
              <Item
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <img alt="icon" src={BuyIcon} className="feeIcon" />
                <span
                  style={{
                    display: "flex",
                    flexDirection: windowWidth < 600 ? "column" : "row",
                  }}
                >
                  <span style={{ textAlign: "left", paddingRight: "1rem" }}>
                    <h2>Buy property</h2>
                    <p>2% + 18% GST from sellers</p>
                    <p>1% + 18% GST from buyers</p>
                  </span>
                  <span className="vl"></span>
                  <span style={{ fontWeight: 600, textAlign: "left" }}>
                    NO Brokerage for {windowWidth > 600 && (<br />)}
                    prelaunch
                    <br />
                    from Buyers
                  </span>
                </span>
              </Item>
            </Grid>
            <Grid item xs={11} style={{paddingLeft: "0px", marginBottom: "1rem"}}>
              <Item
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <img alt="icon" src={StampIcon} className="feeIcon" />
                <span style={{ textAlign: "left", paddingRight: "1rem" }}>
                  <h2>More info</h2>
                  <ul style={{ marginLeft: "1rem" }}>
                    <li>
                      Other services fee will be specified as per transaction
                    </li>
                    <li>
                      Confirm brokerage in writing before transaction in writing{" "}
                    </li>
                  </ul>
                </span>
              </Item>
            </Grid>
          </Grid>
        </Box>

        {/* buy and search*/}
      </Grid>
    </div>
  );
}
