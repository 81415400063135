import React from "react";
import "./footer.scss";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import Logotitle from "../assets/Yuji_evoke_text.png";
import LinkedIn from "../assets/LinkedIn.svg";
import Facebook from "../assets/Facebook.svg";
// import Logo from "../assets/Yuji_evoke_logo.png";
import Instagram from "../assets/Instagram.svg";
import GoogleMaps from "../assets/Google_Maps.png";
import { Grid, Stack, Typography } from "@mui/material";
import "./footer.scss";

function Footer() {
  return (
    <Grid
      id="footerContainer"
      container
      direction={{ md: "row", xs: "column" }}
      align-items={{ md: "center", xs: "center" }}
      rowSpacing={{ md: 0, xs: 8 }}
      justifyContent={{ md: "space-evenly", xs: "space-evenly" }}
      style={{ marginTop: "20vh" }}
    >
      <Grid
        item
        container
        direction={{ md: "column", xs: "column" }}
        className="firstBox"
        alignItems={{ md: "flex-start", xs: "center" }}
        justifyContent={{ md: "space-between", xs: "space-around" }}
        md={6}
        xs={12}
        order={{ md: 1, xs: 3 }}
        rowSpacing={{ md: 3, xs: 3 }}
      >
        <Grid item className="firstBoxItem1">
          <Stack
            spacing={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <span className="sq_header">
              <h2>
                <span style={{ color: "red" }}>SQUARE </span>
                <span style={{ color: "black" }}>ONE REALTY</span>
              </h2>
            </span>
            {/* <img className="footerLogo" src={Logo} alt="logo" /> */}
            {/* <img className="logoTitle" src={Logotitle} alt="logo-title" /> */}
          </Stack>
        </Grid>

        <Grid item className="firstBoxItem2">
          <Typography className="descr" variant="body1">
            Your Premier Partner for Exceptional Real Estate Solutions in
            Bangalore. Discover Our Expertise in Buying, Selling, and Leasing
            Properties. Let Us Guide You to Your Dream Home or Investment
            Opportunity Today!
          </Typography>
        </Grid>

        <Grid item className="firstBoxItem3">
          <Stack
            direction={{ md: "row", xs: "row" }}
            spacing={{ md: 4, xs: 5 }}
            justifyContent={{ md: "flex-start", xs: "space-evenly" }}
            alignItems={{ md: "flex-start", xs: "center" }}
          >
            {/* <img className='socialIcons' src={Facebook} alt='fb'/> */}
            {/* <img className='socialIcons' src={Twitter} alt='tw'/> */}
            {/* <a
              target="_blank"
              href="https://www.instagram.com/yuj_evoke?igsh=MWtibXBkNzFzZDMzcg=="
            >
              <img className="socialIcons" src={Instagram} alt="Ig" />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/yuj-evoke-yogi/about/"
            >
              <img className="socialIcons" src={LinkedIn} alt="ln" />
            </a> */}
          </Stack>
        </Grid>
        <Grid item className="firstBoxItem4">
          <Typography className="copyRights" variant="body1" align="left">
            © 2024. All rights reserved | Powered by{" "}
            <a href="https://digiigo.com/" target="_blank">
              Digiigo Technologies
            </a>
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction={{ md: "row", xs: "row" }}
        justifyContent={{ md: "start", xs: "start" }}
        alignItems={{ md: "flex-start", xs: "flex-start" }}
        md={5}
        xs={12}
        order={{ md: 1, xs: 5 }}
        className="secondAndThirdContainer"
      >
        <Grid item className="firstBoxItem3">
          <Stack
            direction={{ md: "row", xs: "row" }}
            spacing={{ md: 6, xs: 6 }}
            justifyContent={{ md: "flex-start", xs: "space-evenly" }}
            alignItems={{ md: "flex-start", xs: "left" }}
            className="firstBoxItem3_icons"
          >
            <a
              target="_blank"
              href="https://www.facebook.com/share/GmuJa5HKCHKLj4QY/?mibextid=LQQJ4d"
            >
              <img className="socialIcons" src={Facebook} alt="fb" />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/square_one_realty?igsh=MWs4cmk5cTdyZDUzaw%3D%3D&utm_source=qr"
            >
              <img className="socialIcons" src={Instagram} alt="Ig" />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/squareonerealtybengaluru/"
            >
              <img className="socialIcons" src={LinkedIn} alt="ln" />
            </a>
            <a
              target="_blank"
              href="https://maps.app.goo.gl/45jJod69AMBh7o948?g_st=ic"
            >
              <img
                className="socialIcons"
                src={GoogleMaps}
                alt="tw"
                style={{ height: "1.375rem" }}
              />
            </a>
          </Stack>
        </Grid>
        <Grid item className="firstBoxItem3 firstBoxItem3phoneversion">
          <div>Square One Realty - A Real Estate Company</div>
          <div>
            Address:{" "}
            <a href="https://maps.app.goo.gl/45jJod69AMBh7o948?g_st=ic">
              {" "}
              #36, Idhaya Complex, Hennur Road, Bangalore -560077.
            </a>
          </div>
          <div>
            Mobile: <a href="tel:+919845291967">+919845291967</a>
          </div>
          <div>
            Email: <a>info@sq1realty.homes</a>
          </div>
        </Grid>

        <Grid item className="firstBoxItem3"></Grid>
      </Grid>

      {/* <Grid
        item
        container
        direction={{ md: "row", xs: "row" }}
        justifyContent={{ md: "space-around", xs: "space-between" }}
        alignItems={{ md: "flex-start", xs: "flex-start" }}
        md={4}
        xs={12}
        order={{ md: 2, xs: 2 }}
        className="secondAndThirdContainer"
      >
        <Grid item className="secondBox">
          <Typography className="secondBoxTitle" variant="h5">
            Take a tour
          </Typography>
          <Stack direction="column" alignItems="flex-start">
            <a className="secondBoxLinks" href="">
              Classes we offer
            </a>
            <a className="secondBoxLinks" href="">
              About Me
            </a>
            <a className="secondBoxLinks" href="">
              Our Services
            </a>
          </Stack>
        </Grid>
      </Grid> */}

      {/* <Grid
        item
        container
        direction={{ md: "column", xs: "column" }}
        justifyContent={{ md: "center", xs: "flex-start" }}
        alignItems={{ md: "flex-start", xs: "center" }}
        rowSpacing={{ md: 3.5, xs: 3.5 }}
        className="fourthBox"
        md={4}
        xs={12}
        order={{ md: 3, xs: 1 }}
      >
        <Grid item>
          <Typography className="fourthBoxTitle" variant="h5">
            Subscribe
          </Typography>
        </Grid>
        <Grid item>
          <Typography className="fourthBoxDescr" variant="body1">
            Subscribe to get latest property, blog news from us
          </Typography>
        </Grid>
        <Grid item className='emailContainer'>
                  <input className='fourthBoxEmail' placeholder='Email Address'/>
                  <ArrowCircleRightRoundedIcon id='emailSendIcon'/> 
              </Grid>
      </Grid> */}
    </Grid>
  );
}

export default Footer;
