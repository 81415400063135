import React, { useState } from "react";
import { Grid} from "@mui/material";
import "./components.scss";
import HowItWorksCard from "./cards/howitworksCard";
import Icon1 from "../../../assets/Icon.svg";
import Icon2 from "../../../assets/Icon1.svg";
import Icon3 from "../../../assets/Icon (2).svg";
import Icon4 from "../../../assets/Icon2.svg";
import Icon5 from "../../../assets/Icon3.svg";

function HowItWorks(props) {
  return (
    <div className="howitworksContainer">
      <Grid container>
        <Grid container className="howitworksText" alignItems="center" justifyContent="center">
            <div style={{width: "262px",height: "55px",fontFamily: "Manrope",fontStyle: "normal",
            fontWeight: "600",fontSize: "40px",lineHeight: "55px",color: "#000000",textAlign: "center"}}>
                How it works?
            </div>
        </Grid>
        <Grid container className="howitworksCards" spacing={10}>
          <Grid item xs={12} sm={12} md={4} lg={2} className="howItWorksElement">
            <HowItWorksCard
              color="#9672FF"
              icon={Icon1}
              title="Understanding Client property needs"
              info="Wonder twenty hunted and put income set desire expect. Am cottage calling."
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={2} className="howItWorksElement">
            <HowItWorksCard
              color="#4DDFFD"
              icon={Icon2}
              title="Property analytics & recommendation"
              info="Conveying or northward offending admitting perfectly my. Colonel gravit and moonlight."
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={2} className="howItWorksElement">
            <HowItWorksCard
              color="#9672FF"
              icon={Icon3}
              title="Site visits & final selection"
              info="Moderate children at of outweigh it. Unsatiable it considered invitation he travelling insensible."
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={2} className="howItWorksElement">
            <HowItWorksCard
              color="#4DDFFD"
              icon={Icon4}
              title="Negotiation & Legal due diligence"
              info="Moderate children at of outweigh it. Unsatiable it considered invitation he travelling insensible."
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={2} className="howItWorksElement">
            <HowItWorksCard
              color="#9672FF"
              icon={Icon5}
              title="Transactions loans & paperwork"
              info="Moderate children at of outweigh it. Unsatiable it considered invitation he travelling insensible."
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
export default HowItWorks;
