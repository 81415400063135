import { Button, Grid, Link, Stack, Typography } from "@mui/material";
import React from "react";
import "./components_bharath.scss";
import HomeBg from "../../../assets/CoverPage.png";
import HomeBg1 from "../../../assets/CoverPage2.jpg";
import HomeBg2 from "../../../assets/CoverPage3.jpg";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const spanStyle = {
  padding: "20px",
  background: "#efefef",
  color: "#000000",
};

const divStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundSize: "cover",
  height: "400px",
};
const slideImages = [
  {
    url: HomeBg,
    caption: "Slide 1",
  },
  {
    url: HomeBg1,
    caption: "Slide 2",
  },
  {
    url: HomeBg2,
    caption: "Slide 3",
  },
];

export default function NavHomeSearch() {
  const windowWidth = window.innerWidth;

  return (
    <div style={{ height: "90vh", marginTop: "5vh" }}>
      <Slide>
        {slideImages.map((slideImage, index) => (
          <div key={index}>
            <img
              style={{
                width: "100%",
                height: "90vh",
                overflow: "hidden",
                objectFit: "cover",
              }}
              src={slideImage.url}
              alt="backgroundimage"
            />
          </div>
        ))}
      </Slide>
      {/* <img
        src={HomeBg}
        style={{ width: windowWidth, height: "90vh" }}
        className="homeBg"
        alt="backgroundimage"
      /> */}
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        className="navHomeContainer"
      >
        {/* home section body */}
        <Grid item className="navHomeBodyContainer" style={{ top: "10vh" }}>
          <Typography variant="h1" className="bodyTitle">
            <span className="sq_header">
              <h2>
                <span style={{ color: "red" }}>SQUARE </span>
                <span style={{ color: "black" }}>ONE REALTY</span>
              </h2>
            </span>
          </Typography>
          <Typography className="bodyDescr" variant="body1">
            RESIDENTIAL | COMMERCIAL
          </Typography>
          <Typography className="" variant="body1">
            <b style={{fontSize: "1.125rem"}}>Mohamed Umair</b>
          </Typography>
          <Typography className="" variant="body1">
            Real Estate Specialist
          </Typography>
        </Grid>
        {/* buy and search*/}
      </Grid>
    </div>
  );
}
