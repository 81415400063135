import React, { useState, useEffect } from "react";
import "./homePage.scss";
import City from "./components/city";
import HowItWorks from "./components/howitworks";
import LegalServices from "./components/legalservices";
import ContactUs from "./components/contactus";
import FeaturedList from "../homePage/components/FeaturedList";
import NavHomeSearch from "../homePage/components/NavHomeSearch";
import TopOffers from "../homePage/components/TopOffers";
import About from "../homePage/components/About";
import Pricing from "./components/Pricing";

import Footer from "../../Components/Footer";
import HomeBg from "../../assets/walogo.png";
import Buildings from "../../assets/buildings.gif";
import Member from "../homePage/components/Member"

const windowWidth = window.innerWidth;

function HomePage(props) {
  const [loading, setLoading] = useState(true);

  // const LoadingScreen = () => (
  //   <div className="loading-screen">
  //     <img
  //       height={400}
  //       width={400}
  //       className="socialIcons"
  //       src={Buildings}
  //       alt="ln"
  //     />
  //     <span className="sq_header">
  //       <h2>
  //         <span style={{ color: "red" }}>SQUARE </span>
  //         <span style={{ color: "black" }}>ONE REALTY</span>
  //       </h2>
  //     </span>
  //     <h2>LOADING...</h2>
  //   </div>
  // );

  // useEffect(() => {
  //   // Check if the document is already loaded
  //   if (document.readyState === "complete") {
  //     setLoading(false);
  //   } else {
  //     // Define the load event handler
  //     const handleLoad = () => {
  //       setLoading(false);
  //     };

  //     // Add the event listener
  //     window.addEventListener("load", handleLoad);

  //     // Clean up the event listener on unmount
  //     return () => {
  //       window.removeEventListener("load", handleLoad);
  //     };
  //   }
  // }, []);

  return (
    <>
      {/* {loading ? ( */}
        {/* <LoadingScreen /> */}
      {/* ) : ( */}
        <div className="homepage">
          {/* <img src={HomeBg} style={{width:"100%", position: "fixed", height: "100vh"}} className='homeBg' alt='backgroundimage'/> */}

          <NavHomeSearch />
          <HowItWorks />
          {/* <TopOffers />
      <About/> */}
          {/* <HowItWorks/> */}
          <FeaturedList />
          <TopOffers />
          {/* <City/> */}
          {/* <LegalServices/> */}
          {/* <ContactUs/>       */}
          <Pricing />
          <Member />
          <Footer />
          <div
            className="waicon"
            style={{
              width: "4rem",
              height: "4rem",
              position: "fixed",
              bottom: 32,
              right: 58,
            }}
          >
            <a aria-label="Chat on WhatsApp" href="https://wa.me/919845291967">
              <img
                src={HomeBg}
                style={{
                  width: "10rem",
                  position: "fixed",
                  bottom: 10,
                  right: 10,
                }}
              />
            </a>
          </div>
        </div>
      {/* )} */}
    </>
  );
}
export default HomePage;
